import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { EmailUnsubscribeToken } from '../../../../../common/dto/emails.dto';
import { ServerUrls } from '../../../../../common/dto/server-urls';

@Injectable()
export class EmailUnsubscribeService {
  constructor(
    private _http: CoolHttp,
  ) {
  }

  public async unsubscribeEmailAsync(token: EmailUnsubscribeToken) {
    await this._http.postAsync(`api/${ ServerUrls.Email.children.Unsubscribe.absoluteUrl }`, {
      token: token,
    });
  }
}
