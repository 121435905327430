import { Environment } from './environment.interface';
import versionInfo from '../../../version.json';
import { ApplicationComponent } from '../../../common/dto/app.dto';

export const environment: Environment = {
  production: true,

  version: versionInfo.version,

  serverUrl: 'https://api.rpgbench.com',

  logging: {
    enabled: true,

    dsn: 'https://1d263eb120acca0b137250a9a63cdaed@o4506331993407488.ingest.sentry.io/4506332003827712',
  },

  tracking: {
    enabled: true,
  },

  links: {
    termsUrl: 'https://www.rpgbench.com/terms',
    privacyUrl: 'https://www.rpgbench.com/privacy',
    websiteUrl: 'https://www.rpgbench.com',
    blogUrl: 'https://blog.rpgbench.com',
    faqUrl: 'https://www.rpgbench.com/faq',
    supportEmail: 'support@rpgbench.com',
  },

  appComponentUrls: {
    [ApplicationComponent.App]: 'https://www.rpgbench.com',
    [ApplicationComponent.Login]: 'https://login.rpgbench.com',
  },
};
