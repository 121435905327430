import { RouteLocationNode } from '../model/route-location-node';

export const ServerUrls = {
  GameEntities: new RouteLocationNode('game-entities', {
    children: {
      Get: new RouteLocationNode('', {
        queryParams: {
          type: 'type',
          filter: 'filter',
          skip: 'skip',
          take: 'take',
        },
      }),
      GetById: new RouteLocationNode(':gameEntityId', {
        params: {
          gameEntityId: 'gameEntityId',
        },
      }),

      DeleteById: new RouteLocationNode(':gameEntityId', {
        params: {
          gameEntityId: 'gameEntityId',
        },
      }),

      Upsert: new RouteLocationNode(''),

      DocumentFieldNames: new RouteLocationNode('documents/fields/names'),
    },
  }),

  GameEntityTemplates: new RouteLocationNode('game-entity-templates', {
    children: {
      Get: new RouteLocationNode('', {
        queryParams: {
          type: 'type',
        },
      }),
      GetById: new RouteLocationNode(':gameEntityTemplateId', {
        params: {
          gameEntityTemplateId: 'gameEntityTemplateId',
        },
      }),

      DeleteById: new RouteLocationNode(':gameEntityTemplateId', {
        params: {
          gameEntityTemplateId: 'gameEntityTemplateId',
        },
      }),

      Upsert: new RouteLocationNode(''),
    },
  }),

  Campaigns: new RouteLocationNode('campaigns', {
    children: {
      Get: new RouteLocationNode(''),

      GetById: new RouteLocationNode(':campaignId', {
        params: {
          campaignId: 'campaignId',
        },
      }),

      Upsert: new RouteLocationNode(''),

      Sessions: new RouteLocationNode(':campaignId/sessions', {
        children: {
          Get: new RouteLocationNode(''),
          GetById: new RouteLocationNode(':campaignSessionId', {
            params: {
              campaignSessionId: 'campaignSessionId',
            },
          }),

          Create: new RouteLocationNode(''),

          Sections: new RouteLocationNode(':campaignSessionId/sections', {
            children: {
              Get: new RouteLocationNode(''),

              Upsert: new RouteLocationNode(''),

              Delete: new RouteLocationNode(':campaignSessionSectionId', {
                params: {
                  campaignSessionSectionId: 'campaignSessionSectionId',
                },
              }),

              Entities: new RouteLocationNode(':campaignSessionSectionId/entities', {
                children: {
                  Get: new RouteLocationNode(''),

                  Upsert: new RouteLocationNode(''),

                  GetById: new RouteLocationNode(':campaignSessionSectionEntityId', {
                    params: {
                      campaignSessionSectionEntityId: 'campaignSessionSectionEntityId',
                    },
                  }),

                  UpdatePosition: new RouteLocationNode(':campaignSessionSectionEntityId/position', {
                    params: {
                      campaignSessionSectionEntityId: 'campaignSessionSectionEntityId',
                    },
                  }),

                  UpdateVisibility: new RouteLocationNode(':campaignSessionSectionEntityId/visibility', {
                    params: {
                      campaignSessionSectionEntityId: 'campaignSessionSectionEntityId',
                    },
                  }),

                  Delete: new RouteLocationNode(':campaignSessionSectionEntityId', {
                    params: {
                      campaignSessionSectionEntityId: 'campaignSessionSectionEntityId',
                    },
                  }),
                },
                params: {
                  campaignSessionSectionId: 'campaignSessionSectionId',
                },
              }),
            },
            params: {
              campaignSessionId: 'campaignSessionId',
            },
          }),
        },
        params: {
          campaignId: 'campaignId',
        },
      }),

      Players: new RouteLocationNode(':campaignId/players', {
        children: {
          Get: new RouteLocationNode(''),

          DeleteById: new RouteLocationNode(':playerId', {
            params: {
              playerId: 'playerId',
            },
          }),

          InviteToken: new RouteLocationNode('invite-token', {
            children: {
              Create: new RouteLocationNode(''),

              Redeem: new RouteLocationNode('redeem'),
            },
          }),
        },
        params: {
          campaignId: 'campaignId',
        },
      }),
    },
  }),

  Authentications: new RouteLocationNode('authentications', {
    children: {
      Google: new RouteLocationNode('google', {
        children: {
          Register: new RouteLocationNode(''),
          Login: new RouteLocationNode('login'),
          Callback: new RouteLocationNode('callback'),
        },
      }),

      Password: new RouteLocationNode('password', {
        children: {
          Register: new RouteLocationNode(''),
          Login: new RouteLocationNode('login'),
          PasswordChange: new RouteLocationNode('password-change'),
          PasswordReset: new RouteLocationNode('password-reset', {
            children: {
              CreatePasswordReset: new RouteLocationNode(''),
              Validate: new RouteLocationNode('validate'),
            },
          }),
          Validate: new RouteLocationNode('validate', {
            children: {
              Again: new RouteLocationNode('again'),
            },
          }),
        },
      }),

      Session: new RouteLocationNode('session', {
        children: {
          CreateNew: new RouteLocationNode(''),
          OneTimeToken: new RouteLocationNode('one-time-token'),
          FromOneTimeToken: new RouteLocationNode('from-one-time-token'),
        },
      }),

      Logout: new RouteLocationNode('logout'),
    },
  }),

  Email: new RouteLocationNode('email', {
    children: {
      Unsubscribe: new RouteLocationNode('unsubscribe'),
    },
  }),

  Health: new RouteLocationNode('health', {
    children: {
      Check: new RouteLocationNode(''),
      HTMLCheck: new RouteLocationNode('html'),
      Info: new RouteLocationNode('info'),
    },
  }),
};