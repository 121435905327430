import { Inject, Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { Store } from '@ngxs/store';
import { CoolLocalStorage } from '@angular-cool/storage';
import { SetSessionAction } from '../../login-app.state';
import { jwtDecode } from 'jwt-decode';
import { UserSession } from '../../../../../common/dto/user-session.dto';
import { lastValueFrom } from 'rxjs';
import { OneTimeAuthToken, RegistrationRequestDTO } from '../../../../../common/dto/auth.dto';
import { ApplicationComponent } from '../../../../../common/dto/app.dto';
import { WINDOW } from './injection-tokens';
import { UrlUtils } from '../../../../../common/utils/url.utils';
import { ServerUrls } from '../../../../../common/dto/server-urls';

export const API_TOKEN_STORAGE_KEY = 'rpg-bench-token';

@Injectable()
export class AuthenticationService {
  constructor(
    private _store: Store,
    private _http: CoolHttp,
    private _localStorage: CoolLocalStorage,
    @Inject(WINDOW) private _window: Window,
  ) {
  }

  public async initializeAsync() {
    const savedApiToken = this._localStorage.getItem(API_TOKEN_STORAGE_KEY);

    if (savedApiToken) {
      this._setApiToken(savedApiToken);
    }

    await this.refreshUserSessionAsync();
  }

  public async refreshUserSessionAsync() {
    let response: any = undefined;

    try {
      response = await this._http.postAsync<{
        token: string
      }>(`api/${ ServerUrls.Authentications.children.Session.children.CreateNew.absoluteUrl }`, {
        component: ApplicationComponent.Login,
      });
    } catch {
    }

    await this._setSessionFromTokenResponseAsync(response);
  }

  public async registerWithPasswordAsync(details: RegistrationRequestDTO) {
    await this._http.postAsync(`api/${ ServerUrls.Authentications.children.Password.children.Register.absoluteUrl }`, details);

    await this.loginWithPasswordAsync({
      email: details.email,
      password: details.password,
      component: ApplicationComponent.Login,
    });
  }

  public async loginWithPasswordAsync(user: { email: string; password: string, component: ApplicationComponent }) {
    const response = await this._http.postAsync<{
      token: string
    }>(`api/${ ServerUrls.Authentications.children.Password.children.Login.absoluteUrl }`, user);

    await this._setSessionFromTokenResponseAsync(response);
  }

  public async logoutAsync() {
    await this._http.postAsync(`api/${ ServerUrls.Authentications.children.Logout.absoluteUrl }`);

    this._localStorage.removeItem(API_TOKEN_STORAGE_KEY);

    this._http.deregisterGlobalHeader('Authorization');

    await lastValueFrom(this._store.dispatch(new SetSessionAction(undefined)));
  }

  private async _setSessionFromTokenResponseAsync(response: { token: string }) {
    let session: UserSession | undefined = undefined;

    if (response?.token) {
      session = jwtDecode<{ session: UserSession }>(response.token)?.session;
    }

    this._setApiToken(response?.token);

    await lastValueFrom(this._store.dispatch(new SetSessionAction(session)));
  }

  private _setApiToken(token: string | undefined) {
    this._localStorage.setItem(API_TOKEN_STORAGE_KEY, token || '');

    if (token) {
      this._http.registerGlobalHeader({
        key: 'Authorization',
        value: `Bearer ${ token }`,
      });
    } else {
      this._http.deregisterGlobalHeader('Authorization');
    }
  }

  public async validateEmailAsync(token: string) {
    await this._http.postAsync(`api/${ ServerUrls.Authentications.children.Password.children.Validate.absoluteUrl }`, {
      validationToken: token,
    });
  }

  public async resetPasswordAsync({ newPassword, email, token }: { newPassword: string; email: string; token: any }) {
    await this._http.postAsync(`api/${ ServerUrls.Authentications.children.Password.children.PasswordReset.children.Validate.absoluteUrl }`, {
      email: email,
      newPassword: newPassword,
      token: token,
    });
  }

  public async sendForgotPasswordEmailAsync(email: string) {
    await this._http.postAsync(`api/${ ServerUrls.Authentications.children.Password.children.PasswordReset.children.CreatePasswordReset.absoluteUrl }`, {
      email: email,
    });
  }

  public async resendValidationEmailAsync() {
    await this._http.postAsync(`api/${ ServerUrls.Authentications.children.Password.children.Validate.children.Again.absoluteUrl }`);
  }

  public async createOneTimeAuthTokenAsync(): Promise<{ token: OneTimeAuthToken }> {
    return await this._http.postAsync(`api/${ ServerUrls.Authentications.children.Session.children.OneTimeToken.absoluteUrl }`);
  }

  public async loginWithGoogleAsync() {
    this._window.location.href = UrlUtils.cleanUrl(`${ this._http.baseUrl }/api/${ ServerUrls.Authentications.children.Google.children.Login.absoluteUrl }`);
  }

  public async registerWithGoogleAsync() {
    this._window.location.href = UrlUtils.cleanUrl(`${ this._http.baseUrl }/api/${ ServerUrls.Authentications.children.Google.children.Register.absoluteUrl }`);
  }

  public async tryCreateSessionFromOneTimeAuthTokenAsync(oneTimeAuthToken: OneTimeAuthToken): Promise<boolean> {
    try {
      const response = await this._http.postAsync<{
        token: string
      }>(`api/${ ServerUrls.Authentications.children.Session.children.FromOneTimeToken.absoluteUrl }`, {
        token: oneTimeAuthToken,
        component: ApplicationComponent.Login,
      });

      await this._setSessionFromTokenResponseAsync(response);

      return true;
    } catch (e: any) {
      console.error(e);
    }

    return false;
  }
}
