import { ObjectUtils } from '../utils/object.utils';

export enum FeaturePermission {
  Feature_GameEntities = 'feature_game-entities',
  Feature_Campaigns = 'feature_campaigns',

  Permission_ManageSubscription = 'permission_manage-subscription',
}

export enum FeatureLimitationKey {
  NPCCount = 'npcCount',
}

export interface FeatureLimitations {
  [FeatureLimitationKey.NPCCount]: number;
}

type FeatureLimitationMergeStrategy = 'greater' | 'lesser';

const FeatureLimitationMergeStrategyMappings: {
  [limitation in FeatureLimitationKey]: FeatureLimitationMergeStrategy;
} = {
  [FeatureLimitationKey.NPCCount]: 'greater',
};

export interface FeaturePermissions {
  permissions: FeaturePermission[];

  limitations: FeatureLimitations;
}

export function isFeatureEnabled(key: FeaturePermission, permissions: FeaturePermissions | undefined): boolean {
  if (!permissions?.permissions?.length) {
    return false;
  }

  return permissions.permissions.includes(key);
}

export function isFeatureDisabled(key: FeaturePermission, permissions: FeaturePermissions | undefined): boolean {
  return !isFeatureEnabled(key, permissions);
}

export function isAnyFeatureEnabled(
  permissionKeys: FeaturePermission[],
  permissions: FeaturePermissions | undefined,
): boolean {
  return permissionKeys.some((_) => isFeatureEnabled(_, permissions));
}

export function areFeaturesEnabled(
  permissionKeys: FeaturePermission[],
  permissions: FeaturePermissions | undefined,
): boolean {
  return !permissionKeys.some((_) => !isFeatureEnabled(_, permissions));
}

export function mergeFeatureLimitations(base: FeatureLimitations, limitations: Partial<FeatureLimitations>[]): Readonly<FeatureLimitations> {
  const result = <FeatureLimitations>ObjectUtils.deepCopy(base);

  for (const limitation of limitations) {
    for (const [prop, strategy] of <[FeatureLimitationKey, FeatureLimitationMergeStrategy][]>Object.entries(FeatureLimitationMergeStrategyMappings)) {
      const limitationValue = limitation[prop];

      if (limitationValue === undefined) {
        continue;
      }

      if (
        (strategy === 'greater' && limitationValue > result[prop])
        ||
        (strategy === 'lesser' && limitationValue < result[prop])
      ) {
        result[prop] = <any>limitationValue;
      }
    }
  }

  return result;
}
