<div
  class="page h-100 text-center content-center p-3 p-md-5"
>
  <div class="d-inline-block text-center">
    <div class="pb-3">
      <app-logo height="140px" color="dark"/>
    </div>

    <div class="card-box mt-4 px-3 px-md-5 py-4" style="min-width: 350px">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
